import mixpanel from "mixpanel-browser";
import * as Fathom from "fathom-client";
import { isProduction } from "@/shared/config";

const FATHOM_TOKEN = process.env.NEXT_PUBLIC_FATHOM_SITE_ID;
const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

/**
 * Events that we track
 *
 * Naming conventions:
 * - use past tense
 *
 * - Format: Noun Verb
 *   - Example: Buy Button Clicked
 *   - Example: Purchase Completed
 *
 * Organizing names with categories:
 * - use categories to organize events by logical parts of the application (a component, a page)
 *
 * - Format: Category - Noun Verb
 * - Example: Header - Connect Wallet Button Clicked
 */

type TrackedEvent =
  // Page Viewed : when a page has been viewed
  | "Page Viewed"

  // Share Button Clicked - When a user clicks a share button. props {source: "purchase modal | listing modal", platform: "twitter" }
  | "Share Button Clicked"

  // Purchase Started - when a purchase has started (user has cliekd purchase and waiting for user to sign)
  | "Purchase Started"

  // Purchase Completed - users successfully purchased an item (if user leaves page before tx finished this wont trigger)
  | "Purchase Completed"

  // Collection Page - Generate OOB Button Clicked - when a user clicked the button to generate a collection OOB output
  | "Collection Page - Generate OOB Button Clicked";

const FathomGoals: { [trackedEvent: string]: string } = {
  "Purchase Started": "H05DQIRN",
  "Purchase Completed": "VWY04O7M",
  "Collection Page - Generate OOB Button Clicked": "PNMIKKLZ",
};

class Analytics {
  private fathomInitialized: boolean;
  private mixpanelInitialized: boolean;

  constructor() {
    this.fathomInitialized = false;
    this.mixpanelInitialized = false;
  }

  init() {
    if (!this.fathomInitialized) {
      if (FATHOM_TOKEN) {
        Fathom.load(FATHOM_TOKEN);
        this.fathomInitialized = true;
      }
    }

    if (!this.mixpanelInitialized) {
      if (MIXPANEL_TOKEN) {
        mixpanel.init(MIXPANEL_TOKEN);
        this.mixpanelInitialized = true;
      }
    }
  }

  trackPageView(data: { url: string; pathname: string; query?: object }) {
    try {
      this.init();
      this.track("Page Viewed", data);
      if (this.fathomInitialized) Fathom.trackPageview();
    } catch (err) {
      console.error(err);
    }
  }

  track(eventName: TrackedEvent, properties?: any) {
    if (!isProduction())
      console.log(`analytics - track: ${eventName}`, properties);

    try {
      this.init();
      if (this.mixpanelInitialized) mixpanel.track(eventName, properties);
      if (FathomGoals[eventName]) {
        if (this.fathomInitialized) Fathom.trackGoal(FathomGoals[eventName], 0);
      }
    } catch (err) {
      console.error(err);
    }
  }

  identify(user: { id: string }) {
    // console.log(`analytics - identify:`, user);

    try {
      this.init();
      if (this.mixpanelInitialized) mixpanel.identify(user?.id?.toLowerCase());
    } catch (err) {
      console.error(err);
    }
  }
}

export default new Analytics();
