import axios, { AxiosInstance } from "axios";

import { Platform } from "@/shared/platforms";
import { Activity, ActivityKind } from "@/shared/types/activity";
import { Collection } from "@/shared/types/collection";
import { Asset } from "shared/types/asset";
import { getBasePath } from "@/shared/config";

let instance: AxiosInstance | null = null;

export const getApiClient = () => {
  if (instance) return instance;

  const basePath = getBasePath();

  instance = axios.create({
    baseURL: basePath ? `${basePath}/api` : "/api",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return instance;
};

// Auth
export const privyAuthenticate = async (
  publicAddress: string
): Promise<any> => {
  const response = await getApiClient().post(`/authenticate`, {
    publicAddress: publicAddress,
  });

  if (response.status !== 200) {
    throw new Error("Privy Authenticate failed");
  }

  return response?.data;
};

export const logout = async (): Promise<{ message: string }> => {
  const response = await getApiClient().post<{ message: string }>(`/logout`);

  if (response.status !== 200) {
    throw new Error("Privy Authenticate failed");
  }

  return response?.data;
};

// Apply

export const submitApplication = async (formData: {
  artistName: string;
  email: string;
  discord?: string;
  twitter?: string;
  portfolio: string;
  creativeHistory?: string;
  technicalProficiency: string;
  originalityAck: boolean;
}): Promise<{ success: boolean; message: string }> => {
  try {
    const response = await getApiClient().post("/apply", formData);

    if (response.status !== 200) {
      throw new Error("Application submission failed");
    }

    return response.data;
  } catch (error) {
    console.error("Error submitting application:", error);
    throw new Error("Error submitting application. Please try again.");
  }
};

export const submitResidencyApplication = async (formData: {
  artistName: string;
  email: string;
  twitter?: string;
  instagram?: string;
  portfolio: string;
  statementOfPurpose: string;
  creativeHistory: string;
  whyMarfa: string;
  resourceNeeds: string;
  availability: string;
}): Promise<{ success: boolean; message: string }> => {
  try {
    const response = await getApiClient().post("/apply/residency", formData);

    if (response.status !== 200) {
      throw new Error("Residency application submission failed");
    }

    return response.data;
  } catch (error) {
    console.error("Error submitting residency application:", error);
    throw new Error(
      "Error submitting residency application. Please try again."
    );
  }
};

// Rankings

export const listCollectionRankings = async (options?: {
  sort?: string;
  curationStatus?: string;
  limit?: number;
  offset?: number;
}): Promise<any> => {
  const response = await getApiClient().get(`/rankings/collections`, {
    params: options,
  });

  if (response.status !== 200) {
    throw new Error("Listing collection rankings failed");
  }

  return response?.data;
};

export const listArtistRankings = async (options?: {
  sort?: string;
  limit?: number;
  offset?: number;
}): Promise<any> => {
  const response = await getApiClient().get(`/rankings/artists`, {
    params: options,
  });

  if (response.status !== 200) {
    throw new Error("Listing collection rankings failed");
  }

  return response?.data;
};

// COLLECTIONS

export const listUpcomingMintCollections = async (options: {
  curationStatus?: string;
  limit?: number;
}): Promise<any> => {
  return listCollections({ ...options, onlyUpcomingMints: true });
};

export const listRecentMintedCollections = async (options: {
  curationStatus?: string;
  limit?: number;
}): Promise<any> => {
  return listCollections({ ...options, onlyPastMints: true });
};

export const listCollections = async (options: {
  onlyUpcomingMints?: boolean;
  onlyPastMints?: boolean;
  onlyMintable?: boolean;
  curationStatus?: string;
  artBlocksVerticalName?: string;
  limit?: number;
  artistId?: string;
  sort?: any;
  platform?: string;
  tag?: string[];
  includeHidden?: boolean;
}): Promise<any> => {
  const response = await getApiClient().get(`/collections`, {
    params: options,
  });

  if (response.status !== 200) {
    throw new Error("Listing upcoming collections failed");
  }

  return response?.data;
};

export const readCollection = async (id: string): Promise<Collection> => {
  const response = await getApiClient().get(`/collections/${id}`);

  if (response.status !== 200) {
    throw new Error("Reading collection failed");
  }

  return response?.data;
};

export const readCollectionStats = async (id: string): Promise<any> => {
  const response = await getApiClient().get(`/collections/${id}/stats`);

  if (response.status !== 200) {
    throw new Error("reading collection stats failed");
  }

  return response?.data;
};

export const readCollectionTraits = async (id: string): Promise<any> => {
  const response = await getApiClient().get(`/collections/${id}/traits`);

  if (response.status !== 200) {
    throw new Error("Reading collection traits failed");
  }

  return response?.data;
};

export const exploreCollectionTraits = async ({
  id,
  key,
}: {
  id: string;
  key: string;
}): Promise<any> => {
  const response = await getApiClient().get(
    `/collections/${id}/traits/explore`,
    {
      params: {
        traitType: key,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Exploring collection traits failed");
  }

  return response?.data;
};

export const readCollectionOwners = async (id: string): Promise<any> => {
  const response = await getApiClient().get(`/collections/${id}/owners`);

  if (response.status !== 200) {
    throw new Error("Reading collection owners failed");
  }

  return response?.data;
};

// ASSETS

export const readAsset = async (
  contractAddress: string,
  tokenId: string
): Promise<Asset | null> => {
  const response = await getApiClient().post(`/assets`, {
    filters: {
      contractAddress,
      tokenId,
    },
  });

  if (response.status !== 200) {
    throw new Error("readAsset failed");
  }

  return response?.data?.data?.[0] || null;
};

export interface ListAssetParams {
  filters: {
    ownerAddress?: string;
    collectionId?: string;
    collectionIds?: string[];
    onlyBuyNow?: boolean;
    onlySourceSansa?: boolean;
    traits?: any;
    customTraits?: any;
    tokenId?: string;
  };
  limit?: number;
  offset?: number;
  sort?: string;
}

export const listAssets = async (
  params: ListAssetParams
): Promise<{ data: Asset[]; total: number }> => {
  const response = await getApiClient().post(`/assets`, params);

  if (response.status !== 200) {
    throw new Error("listAssets failed");
  }

  return response?.data;
};

export const listAssetListings = async (
  contractAddress: string,
  tokenId: string
): Promise<any[]> => {
  const response = await getApiClient().get(
    `/assets/${contractAddress}/${tokenId}/listings`
  );

  if (response.status !== 200) {
    throw new Error("listAssetListings failed");
  }

  return response?.data || null;
};

// SEARCH

export const search = async (term: string): Promise<any> => {
  const response = await getApiClient().get(`/search`, {
    params: {
      q: term,
      limit: 3,
    },
  });

  if (response.status !== 200) {
    throw new Error("search failed");
  }

  return response?.data;
};

export const searchAssets = async ({
  query,
  ownerAddress,
  limit = 20,
}: {
  query: string;
  ownerAddress?: string;

  limit: number;
}): Promise<Asset[]> => {
  const response = await getApiClient().get(`/search/assets`, {
    params: {
      q: query,
      ownerAddress,
      limit,
    },
  });

  if (response.status !== 200) {
    throw new Error("search failed");
  }

  return response?.data;
};

export const searchColor = async ({
  colorHex,
  onlyBuyNow,
  limit,
}: {
  colorHex: string;
  onlyBuyNow?: boolean;
  limit?: number;
}): Promise<Asset[]> => {
  const response = await getApiClient().get(`/search/color`, {
    params: {
      colorHex,
      onlyBuyNow,
      limit,
    },
  });

  if (response.status !== 200) {
    throw new Error("search failed");
  }

  return response?.data;
};

// BETA ACESS

export const checkAccess = async (address: string): Promise<any> => {
  const response = await getApiClient().get(`/access`, {
    params: {
      address,
    },
  });

  if (response.status !== 200) {
    throw new Error("access check failed");
  }

  return response?.data;
};

// Chat

export const generateChatAuth = async (walletAddress: string): Promise<any> => {
  const response = await getApiClient().post(`/chat`, {
    address: walletAddress,
  });

  if (response.status !== 200) {
    throw new Error("generateChatAuth failed");
  }

  return response?.data;
};

// ARTISTS

export const readArtist = async (id: string) => {
  const response = await getApiClient().get(`/artists/${id}`);

  if (response.status !== 200) {
    throw new Error("Reading artist failed");
  }

  return response?.data;
};

// USER

export const listUserCollections = async (address: string) => {
  const response = await getApiClient().get(`/users/${address}/collections`);

  if (response.status !== 200) {
    throw new Error("Reading user collections failed");
  }

  return response?.data;
};

// Listings

/**
 * Return reservoir listings for wallet
 */
export const fetchListingsByAddress = async (address: string, params?: any) => {
  const response = await getApiClient().get(`/users/${address}/listings`, {
    params: params,
  });

  if (response.status !== 200) {
    throw new Error("fetchListingsByAddress failed");
  }

  return response?.data;
};

// Bid

// get bids a user has made
export const listOffersMade = async (address: string, params?: any) => {
  const response = await getApiClient().post(`/users/${address}/offers/made`, {
    params: params,
  });

  if (response.status !== 200) {
    throw new Error("Reading user bids made failed");
  }

  return response?.data;
};

export const listOffersReceived = async (address: string, params?: any) => {
  const response = await getApiClient().post(
    `/users/${address}/offers/received`,
    {
      params: params,
    }
  );

  if (response.status !== 200) {
    throw new Error("Reading user bids received failed");
  }

  return response?.data;
};

// Activity

export type ListActivityParams = {
  filters?: {
    kind: ActivityKind;
    platform?: Platform;
    priceMin?: number;
    collectionId?: string;
    priceMax?: number;
    occurredAtAfter?: Date;
    occurredAtBefore?: Date;
    onlySansaListings?: boolean;
    onlyRelistingListings?: boolean;
    onlyNewFloorListings?: boolean;
    onlyNotableAddresses?: boolean;
  };
  limit?: number;
};

export const listActivity = async (
  params: ListActivityParams
): Promise<Activity[]> => {
  const response = await getApiClient().post(`/activity`, params);

  if (response.status !== 200) {
    throw new Error("listActivity failed");
  }

  return response?.data;
};

//  newsletter
export const joinNewsletter = async (emailAddress: string) => {
  const response = await getApiClient().post("newsletter/join", {
    params: {
      email: emailAddress,
    },
  });

  if (response.status !== 200) {
    throw new Error("Joining newsletter failed");
  }

  return response?.data;
};

// PLATFORM / CATEGORIES

export const readCategory = async (slug: string) => {
  const response = await getApiClient().get(`/category/${slug}`);

  if (response.status !== 200) {
    throw new Error("Reading category failed");
  }

  return response?.data;
};

export const getArtistsByPlatform = async (
  platformId: string,
  options: any
) => {
  const response = await getApiClient().get(
    `/platforms/${platformId}/artists`,
    {
      params: options,
    }
  );

  if (response.status !== 200) {
    throw new Error(`Getting ${platformId} artists failed`);
  }

  return response?.data;
};

// get calendar events

export const getCalendarEvents = async () => {
  const response = await getApiClient().get("/calendar/events");

  if (response.status !== 200) {
    throw new Error("Fetching calendar events failed");
  }

  return response?.data;
};

// User profile
export const getWalletProfile = async (walletAddress: string): Promise<any> => {
  const response = await getApiClient().get(`/users/${walletAddress}/profile`);

  if (response.status !== 200) {
    throw new Error("Getting wallet profile data failed");
  }

  return response?.data;
};

export const updateWalletProfile = async (profileData: {
  user_address: string;
  username?: string;
  bio?: string;
  profilePicturePath?: string;
}) => {
  const response = await getApiClient().post(
    `/users/${profileData.user_address}/profile`,
    {
      profileData,
    }
  );

  if (response.status !== 200) {
    throw new Error("Updating wallet profile failed");
  }

  return response?.data;
};

export const getPresignedProfileImageUploadUrl = async (
  fileType: string
): Promise<{ presignedUrl: string; fullFilePath: string }> => {
  const response = await getApiClient().post("/upload/user-profile", {
    contentType: fileType,
  });

  if (response.status !== 200) {
    throw new Error("Failed to get upload URL");
  }

  return response.data;
};
