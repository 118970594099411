import Link from "next/link";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";

import { useAuth } from "@/client/components/common/AuthProvider";
import LogOut from "@/client/components/icons/log-out";
import { formatWalletShort } from "@/client/lib/helpers";
import Dropdown from "@/client/components/frame-design-system/menus/Dropdown";
import DropdownMenu from "@/client/components/frame-design-system/menus/DropdownMenu";
import DropdownMenuRow from "@/client/components/frame-design-system/menus/DropdownMenuRow";
import { getBasePath } from "@/shared/config";
import { getWalletProfile } from "@/client/lib/api";
import { useUserHasClaimableExcessSettlementFunds } from "@/client/components/common/ExcessSettlementFundsManagerContext/hooks";
import { ExcessSettlementFundsClaimModal } from "@/client/components/common/ExcessSettlementFundsClaimModal";
import { Alert } from "@/client/components/icons/alert";
import { cn } from "@/client/lib/classnames";
import { useEnsName } from "wagmi";

type AccountMenu = {
  address: string;
  menuType?: "default" | "mobile";
};

export const AccountMenu = ({ address, menuType = "default" }: AccountMenu) => {
  const router = useRouter();
  const { logout } = useAuth();

  const defaultPfpUrl = `${getBasePath()}/default-avatar.png`;
  const [open, setOpen] = useState<boolean>(false);
  const [profilePfpUrl, setProfilePfpUrl] = useState<string>(defaultPfpUrl);

  const ensNameResult = useEnsName({
    address: address as `0x${string}`,
  });
  const profileTitle =
    ensNameResult.data || formatWalletShort(address as string);

  // close menu if route changes
  useEffect(() => {
    setOpen(false);
  }, [router?.asPath]);

  // fetch profile image
  useEffect(() => {
    const fetchWalletProfile = async () => {
      try {
        const profileData = await getWalletProfile(address);
        const profilePictureUrl = profileData?.profile?.profile_picture?.url;
        const profileImageUrl = profilePictureUrl
          ? profilePictureUrl
          : defaultPfpUrl;
        setProfilePfpUrl(profileImageUrl);
      } catch (error) {
        console.error(`Error fetching wallet profile: ${error}`);
      }
    };

    if (address) {
      fetchWalletProfile();
    }
  }, [address]);

  const handleSignOut = async () => {
    await logout?.();
    setOpen(false);
  };

  const accountDropdownItems: DropdownMenu = {
    items: [
      { title: "Wallet", link: `/wallets/${address}` },
      { title: "Sell", link: "/sell" },
      { title: "Manage Listings", link: "/listings" },
      {
        title: "Manage Offers",
        link: `/wallets/${address}?tab=offers`,
      },
      {
        title: "Sign out",
        type: "button",
        onClick: handleSignOut,
        iconTrailing: <LogOut className="w-5 h-5 text-neutral-500" />,
      },
    ],
  };

  const userHasClaimableExcessSettlementFunds =
    useUserHasClaimableExcessSettlementFunds();
  const [
    showExcessSettlementFundsClaimModal,
    setShowExcessSettlementFundsClaimModal,
  ] = useState<boolean>(false);
  if (userHasClaimableExcessSettlementFunds && accountDropdownItems) {
    accountDropdownItems.items.unshift({
      title: "Claim Funds",
      type: "button",
      onClick: () => {
        setShowExcessSettlementFundsClaimModal(true);
        setOpen(false);
      },
      className: "text-left",
      iconTrailing: <Alert className="w-5 h-5 text-red-600" />,
    });
  } else {
    if (showExcessSettlementFundsClaimModal) {
      setShowExcessSettlementFundsClaimModal(false);
    }
  }

  const listItemClasses =
    "hover:opacity-70 text-p-m py-2 block text-neutral-600 dark:text-neutral-200 text-left";

  const desktopMenu = () => {
    return (
      <div className="relative">
        <Dropdown
          open={open}
          setOpen={setOpen}
          title={profileTitle}
          color="primary"
          inline={false}
          weight="medium"
          avatar={profilePfpUrl}
          notification={userHasClaimableExcessSettlementFunds}
        />
        <div
          className={`${
            open ? "opacity-100" : "opacity-0 translate-y-2 invisible"
          } absolute transform transition top-12 right-0 w-44`}
        >
          {accountDropdownItems ? (
            <DropdownMenu {...accountDropdownItems} />
          ) : null}
        </div>
      </div>
    );
  };

  const mobileMenu = () => {
    return (
      <>
        <Dropdown
          open={open}
          setOpen={setOpen}
          title={profileTitle}
          color="primary"
          inline={true}
          weight="medium"
          packed={false}
          avatar={profilePfpUrl}
          notification={userHasClaimableExcessSettlementFunds}
        />
        {open ? (
          <>
            {accountDropdownItems?.items.map(
              (item: DropdownMenuRow, idx: number) => {
                if (item?.type && item?.type === "button") {
                  return (
                    <button
                      key={item.title}
                      onClick={item.onClick}
                      className={cn(listItemClasses, "flex justify-between")}
                    >
                      <span className="flex gap-1">
                        {item.iconLeading ?? null}
                        {item.title}
                      </span>
                      {item.iconTrailing ?? null}
                    </button>
                  );
                } else {
                  return (
                    <Link
                      href={item.link ? item.link : ""}
                      key={item.title}
                      className={cn(listItemClasses, "flex justify-between")}
                    >
                      <span className="flex gap-1">
                        {item.iconLeading ?? null}
                        {item.title}
                      </span>
                      {item.iconTrailing ?? null}
                    </Link>
                  );
                }
              }
            )}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className="absolute">
        <ExcessSettlementFundsClaimModal
          show={showExcessSettlementFundsClaimModal}
          onClose={() => {
            setShowExcessSettlementFundsClaimModal(false);
          }}
        />
      </div>
      {menuType === "mobile" ? mobileMenu() : desktopMenu()}
    </>
  );
};
