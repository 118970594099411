import {
  MEDIA_BASE_URL as ARTBLOCKS_MEDIA_BASE_URL,
  MEDIA_PROXY_BASE_URL as ARTBLOCKS_MEDIA_PROXY_BASE_URL,
  MEDIA_AB_X_PACE_BASE_URL as ARTBLOCKS_X_PACE_MEDIA_BASE_URL,
  MEDIA_AB_EXPLORATIONS_BASE_URL as ARTBLOCKS_EXPLORATIONS_MEDIA_BASE_URL,
  MEDIA_BRIGHT_MOMENTS_BASE_URL,
  MEDIA_TENNIS_ART_BASE_URL,
} from "shared/artblocks";

import {
  BRAINDROPS_CLIPMATRIX_CREATURES,
  BRAINDROPS_ELEMENTS,
  BRAINDROPS_MINIPODS,
  BRAINDROPS_PODGANS,
  BRAINDROPS_ALL_ART_LOOKS_SAME,
  BRAINDROPS_DETECTIVE_JACK,
  BRAINDROPS_VIEWS,
  MIRAGE_TOME_OF_AURUMS,
} from "./labelledAddresses";

const DEFAULT_WIDTH_THUMBNAIL = 640;
const DEFAULT_WIDTH_NORMAL = 1200;
const DEFAULT_WIDTH_LARGE = 2048;

const CDN_BASE_URL = "https://sansa.twic.pics";
// const IPFS_GATEWAY = "https://sansa.infura-ipfs.io";
const IPFS_GATEWAY = "https://cloudflare-ipfs.com";
const IPFS_PROTOCOl = "ipfs://";
const IPFS_MEDIA_BASE_URL = `${IPFS_GATEWAY}/ipfs`;
const IPFS_CDN_BASE_URL = `${CDN_BASE_URL}/ipfs`;

const IMAGE_CDN_ARTBLOCKS_BASE_URL = "https://sansa.twic.pics/artblocks";
const IMAGE_CDN_AB_X_PACE_BASE_URL = "https://sansa.twic.pics/artblocks-x-pace";
const IMAGE_CDN_AB_EXPLORATIONS_BASE_URL =
  "https://sansa.twic.pics/artblocks-explorations";
const IMAGE_CDN_BIGHT_MOMENTS_BASE_URL =
  "https://sansa.twic.pics/bright-moments";
const IMAGE_CDN_TENNIS_ART_BASE_URL = "https://sansa.twic.pics/tennis-art";

export const IMAGE_CDN_V2_BASE_URL =
  "https://res.cloudinary.com/art-blocks/image/fetch/";

const IGNORED_CDN_COLLECTION_IDS = [
  BRAINDROPS_CLIPMATRIX_CREATURES,
  BRAINDROPS_ELEMENTS,
  BRAINDROPS_MINIPODS,
  BRAINDROPS_PODGANS,
  BRAINDROPS_ALL_ART_LOOKS_SAME,
  BRAINDROPS_DETECTIVE_JACK,
  BRAINDROPS_VIEWS,
  MIRAGE_TOME_OF_AURUMS,
];

export const getImageCdnUrl = (
  imageUrl: string,
  options?: { width: number }
) => {
  // can only use CDN if we have a path
  if (!imageUrl) return imageUrl;
  // ensure image url is image format (video format wouldn't work)
  if (!imageUrl?.endsWith(".png") && !imageUrl?.endsWith(".gif"))
    return imageUrl;

  // try V2 CDN first and if no rewrite is found, fallback to V1
  const cdnUrl = getImageCdnV2Url(imageUrl, options);
  return imageUrl === cdnUrl ? getImageCdnV1Url(imageUrl, options) : cdnUrl;
};

/**
 * Use TwicPics CDN for content that has rewrites setup
 */
export const getImageCdnV1Url = (
  imageUrl: string,
  options?: { width: number }
) => {
  // TODO: move these to contract configuration rewrites
  if (imageUrl?.includes(ARTBLOCKS_MEDIA_BASE_URL)) {
    const url = imageUrl.replace(
      ARTBLOCKS_MEDIA_BASE_URL,
      IMAGE_CDN_ARTBLOCKS_BASE_URL
    );
    if (options?.width) return `${url}?twic=v1/resize=${options.width}`;
    return url;
  }
  if (imageUrl?.includes(ARTBLOCKS_X_PACE_MEDIA_BASE_URL)) {
    const url = imageUrl.replace(
      ARTBLOCKS_X_PACE_MEDIA_BASE_URL,
      IMAGE_CDN_AB_X_PACE_BASE_URL
    );
    if (options?.width) return `${url}?twic=v1/resize=${options.width}`;
    return url;
  }
  if (imageUrl?.includes(ARTBLOCKS_EXPLORATIONS_MEDIA_BASE_URL)) {
    const url = imageUrl.replace(
      ARTBLOCKS_EXPLORATIONS_MEDIA_BASE_URL,
      IMAGE_CDN_AB_EXPLORATIONS_BASE_URL
    );
    if (options?.width) return `${url}?twic=v1/resize=${options.width}`;
    return url;
  }
  if (imageUrl?.includes(MEDIA_BRIGHT_MOMENTS_BASE_URL)) {
    const url = imageUrl.replace(
      MEDIA_BRIGHT_MOMENTS_BASE_URL,
      IMAGE_CDN_BIGHT_MOMENTS_BASE_URL
    );
    if (options?.width) return `${url}?twic=v1/resize=${options.width}`;
    return url;
  }
  if (imageUrl?.includes(MEDIA_TENNIS_ART_BASE_URL)) {
    const url = imageUrl.replace(
      MEDIA_TENNIS_ART_BASE_URL,
      IMAGE_CDN_TENNIS_ART_BASE_URL
    );
    if (options?.width) return `${url}?twic=v1/resize=${options.width}`;
    return url;
  }

  // handle IPFS urls
  if (imageUrl?.includes(IPFS_MEDIA_BASE_URL)) {
    const url = imageUrl.replace(IPFS_MEDIA_BASE_URL, IPFS_CDN_BASE_URL);
    if (options?.width) return `${url}?twic=v1/resize=${options.width}`;
    return url;
  }

  // handle Art Blocks media proxy
  if (imageUrl?.includes(IPFS_MEDIA_BASE_URL)) {
  }

  // handle and cdn image url rewrites
  for (const data of CDN_REWRITES) {
    if (data.imageBaseUrl && data.imageCdnRewriteBaseUrl) {
      if (imageUrl?.includes(data.imageBaseUrl)) {
        const url = imageUrl.replace(
          data.imageBaseUrl,
          data.imageCdnRewriteBaseUrl
        );
        if (options?.width) return `${url}?twic=v1/resize=${options.width}`;
        return url;
      }
    }
  }

  return imageUrl;
};

/**
 * Use Art Blocks cloudinary CDN for Art Blocks media-proxy image
 */
export const getImageCdnV2Url = (
  imageUrl: string,
  options?: { width: number }
) => {
  if (imageUrl?.includes(ARTBLOCKS_MEDIA_PROXY_BASE_URL)) {
    const cdnOptions = options?.width
      ? `f_auto,c_limit,w_${options.width},q_auto`
      : "";

    return `${IMAGE_CDN_V2_BASE_URL}${cdnOptions}/${imageUrl}`;
  }

  return imageUrl;
};

export const getImageThumbnailCdnUrl = (imageUrl: string) => {
  return getImageCdnUrl(normalizeImageUrl(imageUrl), {
    width: DEFAULT_WIDTH_THUMBNAIL,
  });
};

export const getImageNormalCdnUrl = (imageUrl: string) => {
  return getImageCdnUrl(normalizeImageUrl(imageUrl), {
    width: DEFAULT_WIDTH_NORMAL,
  });
};

export const getImageLargeCdnUrl = (imageUrl: string) => {
  return getImageCdnUrl(normalizeImageUrl(imageUrl), {
    width: DEFAULT_WIDTH_LARGE,
  });
};

export const normalizeImageUrl = (imageUrl: string) => {
  return handleIpfsUrls(imageUrl);
};

export const shouldCollectionIgnoreCdn = (collectionId: string) => {
  return IGNORED_CDN_COLLECTION_IDS.includes(collectionId);
};

const handleIpfsUrls = (imageUrl: string) => {
  return imageUrl?.replace(IPFS_PROTOCOl, `${IPFS_MEDIA_BASE_URL}/`);
};

export const CDN_REWRITES: {
  imageBaseUrl: string;
  imageCdnRewriteBaseUrl: string;
}[] = [
  // gmstudio
  {
    imageBaseUrl: "https://gmstudio.mypinata.cloud/ipfs",
    imageCdnRewriteBaseUrl: "https://sansa.twic.pics/gmstudio",
  },
  // plottables
  {
    imageBaseUrl: "https://plottables-mainnet.s3.amazonaws.com",
    imageCdnRewriteBaseUrl: "https://sansa.twic.pics/plottables",
  },
  // toys
  {
    imageBaseUrl: "https://toys-by-0xtechno.grailers.io",
    imageCdnRewriteBaseUrl: "https://sansa.twic.pics/toys-by-0xtechno",
  },
  // ultra dao
  // {
  //   imageBaseUrl: "https://arweave.net",
  //   imageCdnRewriteBaseUrl: "https://sansa.twic.pics/arweave", // TODO: some images are too big for twicpic
  // },
  // endaomint
  {
    imageBaseUrl: "https://endaomint-mainnet.s3.amazonaws.com",
    imageCdnRewriteBaseUrl: "https://sansa.twic.pics/endaomint",
  },
  // gen art
  {
    imageBaseUrl: "https://cdn.gen.art",
    imageCdnRewriteBaseUrl: "https://sansa.twic.pics/gen-art",
  },
  // 256 art
  {
    imageBaseUrl: "https://256art.s3.eu-central-1.amazonaws.com",
    imageCdnRewriteBaseUrl: "https://media.sansa.xyz/256art",
  },
  // Mirage Gallery
  {
    imageBaseUrl:
      "https://bafybeigs5hp7wkiayletua6gbd2b4ucp3xlr4qiuj7zkqq6dbwtwb2bwwm.ipfs.dweb.link",
    imageCdnRewriteBaseUrl: "https://media.sansa.xyz/mirage-gallery",
  },
];
