export enum Market {
  Opensea = "opensea",
  Sansa = "sansa",
  ArtBlocks = "artblocks",
  LooksRare = "looksrare",
  X2Y2 = "x2y2",
  Reservoir = "reservoir",
  Coinbase = "coinbase",
  Rarible = "rarible",
  Foundation = "foundation",
}

/**
 * Markets we support showing listings from
 */
const SUPPORTED_MARKETS = [Market.Opensea, Market.Sansa, Market.ArtBlocks];

export const NATIVE_SOURCE_DOMAIN = "sansa.xyz";

export const getMarketDisplayName = (nameOrDomain: string) => {
  const market = getMarketFromName(nameOrDomain);
  if (!market) return null;
  return getMarketName(market);
};

export const isSupportedMarket = (market: Market) => {
  return SUPPORTED_MARKETS.includes(market);
};

export const isNativeMarket = (market: Market) => {
  return market === Market.Sansa;
};

export const isNativeMarketByNameOrDomain = (nameOrDomain: string) => {
  const market = getMarketFromNameOrDomain(nameOrDomain);
  return market && isNativeMarket(market);
};

export const getMarketFromName = (marketplaceName: string): Market | null => {
  return getMarketFromNameOrDomain(marketplaceName);
};

export const getMarketFromNameOrDomain = (
  marketplaceNameOrDomain: string
): Market | null => {
  const cleanName = marketplaceNameOrDomain?.toLowerCase() || null;
  switch (cleanName) {
    case "opensea":
    case "opensea.io":
      return Market.Opensea;
    case "sansa":
    case "sansa.xyz":
      return Market.Sansa;
    case "artblocks":
    case "artblocks.io":
      return Market.ArtBlocks;
    default:
      return null;
  }
};

export const getMarketName = (market: Market) => {
  switch (market) {
    case Market.Opensea:
      return "OpenSea";
    case Market.Sansa:
    case Market.ArtBlocks:
      return "Art Blocks";
    default:
      return "Marketplace";
  }
};

/**
 * Check if the source is a market we support showing listings for
 */
export const isSourceSupported = (
  sourceName?: string,
  sourceDomain?: string
) => {
  const market =
    (sourceName && getMarketFromNameOrDomain(sourceName)) ||
    (sourceDomain && getMarketFromNameOrDomain(sourceDomain));

  return !!market && isSupportedMarket(market);
};
