import Dropdown from "client/components/common/Dropdown";

export type DateOption = {
  text: string;
  value: string;
  relativeTime: number | null;
  relativeTimeUnit: string | null;
};

const convertDateOptionToDropdownOption = (option: DateOption) => {
  if (!option) return option;
  return {
    ...option,
    name: option.text,
  };
};

export const DateDropdown = ({
  selectedOption,
  options,
  onSelectedOptionChanged,
}: {
  selectedOption: DateOption;
  options: DateOption[];
  onSelectedOptionChanged: (d: DateOption) => void;
}) => {
  return (
    <Dropdown
      selectedOption={convertDateOptionToDropdownOption(selectedOption)}
      setSelectedOption={(option: DateOption) =>
        onSelectedOptionChanged?.(option)
      }
      options={options?.map(convertDateOptionToDropdownOption)}
      vPad="4"
    />
  );
};
