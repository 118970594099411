import { Ethereum } from "../../icons/ethereum";

interface EthLabel {
  amount?: number;
  size?: "xsmall" | "small" | "normal" | "large" | "xl";
  weight?: "normal" | "medium";
  decimals?: number;
  weth?: boolean;
}

const EthLabel = ({
  amount,
  size = "normal",
  weight = "medium",
  decimals = 2,
  weth = false,
}: EthLabel) => {
  const formattedAmount = () => {
    if (!amount) return "--";

    // if amount is larger than 1k show the number as a 1.2k
    if (amount >= 1000) {
      // if it's dead on a number show no decimal (i.e 1k vs 1.0k but if it's 1.2k show that)
      if (amount % 1000 === 0) {
        return (amount / 1000).toFixed(0) + "K";
      } else {
        return (amount / 1000).toFixed(1) + "K";
      }
    }

    return amount.toFixed(decimals);
  };

  const getSize = () => {
    switch (size) {
      case "xsmall":
        return "text-p-xs";
      case "small":
        return "text-p-s";
      case "normal":
        return "text-p-m";
      case "large":
        return "text-p-lg";
      case "xl":
        return "text-p-xl";
    }
  };

  const getIconSize = () => {
    // placement left aligns the icon to avoid icon padding insetting the label
    // containerWidth is used to offset the icon padding

    switch (size) {
      case "xsmall":
      case "small":
        // TODO: Small
        return {
          height: "h-4",
          width: "w-4",
          placement: "ml-[-3px]",
          containerWidth: "w-[.75rem]",
        };
      case "normal":
        return {
          height: "h-5",
          width: "w-5",
          placement: "-ml-[4px]",
          containerWidth: "w-[.95rem]",
        };
      case "large":
        return {
          height: "h-6",
          width: "w-6",
          placement: "-ml-[4px]",
          containerWidth: "w-[1.1rem]",
        };
      case "xl":
        return {
          height: "h-6",
          width: "w-6",
          placement: "-ml-[4px]",
          containerWidth: "w-[1.1rem]",
        };
    }
  };

  return (
    <p
      className={`flex items-center dark:text-white ${
        weight === "medium" ? "font-medium" : null
      } ${getSize()}`}
    >
      <span
        className={`${getIconSize().height} ${
          getIconSize().containerWidth
        } overflow-hidden`}
      >
        <Ethereum
          className={`${weth ? "text-pink-600" : null} ${
            getIconSize().height
          } ${getIconSize().width} ${getIconSize().placement}`}
        />
      </span>
      <span>{formattedAmount()}</span>
    </p>
  );
};

export default EthLabel;
