import CardAssetContained from "./CardAsset/CardAssetContained";
import { useResizeDetector } from "react-resize-detector";
import { EyeOff } from "../../icons/eye-off";
import { Live } from "@/client/components/icons/live";
import { MintState } from "@/shared/types/collection";
import { Collection } from "@/shared/types/collection";
import { Eth } from "client/components/icons/eth";
import PlatformBadge from "@/client/components/common/PlatformBadge";
import { CalendarPlus } from "@/client/components/icons/calendar-plus";
import { formatGoogleCalendarLinkForCollection } from "@/client/lib/helpers";
import React from "react";
import Link from "next/link";
import { useFormattedDate } from "../../hooks/useLocalizedDate";

interface CollectionCardProps {
  collection: Collection;
  showArtistName?: boolean;
  showStats?: boolean;
  showPlatformBadge?: boolean;
}

const MintBadge = ({ mintState }: { mintState: MintState | undefined }) => {
  switch (mintState) {
    case MintState.Upcoming:
      return (
        <div className="flex items-center px-1 py-1 bg-white rounded-lg shadow-xs dark:bg-neutral-700 dark:text-white text-p-xs text-neutral-500">
          Upcoming
        </div>
      );
    case MintState.InProgress:
      return (
        <div className="flex items-center px-1 py-1 pr-2 space-x-1 bg-white rounded-lg shadow-xs dark:bg-neutral-700 text-p-xs">
          <Live />
          <p className="text-black dark:text-white">Live</p>
        </div>
      );
    default:
      return null;
  }
};

const CollectionCardSubHeading = ({
  collection,
  showStats,
}: {
  collection: Collection;
  showStats: boolean;
}) => {
  const { supplyMax, stats, mintStartsAt, mintState } = collection ?? {};
  let mintStateText;

  const formattedStartDate = useFormattedDate(
    mintStartsAt,
    React.useMemo(() => {
      return new Intl.DateTimeFormat("en-US", {
        weekday: "long",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    }, [])
  );

  if (mintState === MintState.Upcoming) {
    mintStateText = mintStartsAt ? `Available ${formattedStartDate}` : "";
  } else if (mintState === MintState.InProgress) {
    mintStateText = `${stats?.supplyTotal ?? 0} / ${supplyMax || "--"} Minted`;
  }

  const auctionStats = () => {
    const sharedStyles = "dark:text-white text-sm flex items-center";
    const EthIcon = () => (
      <Eth size={14} className="ml-1 text-neutral-500 dark:text-neutral-300" />
    );

    return (
      <div className="space-y-1">
        <p className={sharedStyles}>
          Volume: <EthIcon />
          {stats?.volumeTotal
            ? stats.volumeTotal < 1
              ? stats.volumeTotal.toFixed(2)
              : stats.volumeTotal.toFixed(0)
            : "--"}
        </p>
        <p className={sharedStyles}>
          Floor: <EthIcon />
          {stats?.floorPrice || "--"}
        </p>
        <p
          className={`${sharedStyles} text-neutral-500 dark:text-neutral-400 text-xs`}
        >
          <span className="mr-1">{stats?.supplyTotal?.toFixed(0) || "--"}</span>
          Pieces
        </p>
      </div>
    );
  };

  return (
    <div>
      {(mintState === MintState.Upcoming ||
        mintState === MintState.InProgress) && (
        <p className="text-xs text-neutral-500 dark:text-neutral-400">
          {mintStateText}
        </p>
      )}
      {mintState === MintState.Complete &&
        (showStats ? (
          auctionStats()
        ) : (
          <p className="text-sm text-neutral-500 dark:text-neutral-400">
            Mint Complete
          </p>
        ))}
    </div>
  );
};

const CollectionCard = ({
  collection,
  showArtistName = true,
  showStats = false,
  showPlatformBadge = true,
}: CollectionCardProps) => {
  const { width, height, ref } = useResizeDetector();
  if (!collection) return null;

  const {
    mintState,
    imageUrl,
    name = "--",
    artistName = "--",
    platformData,
    mintStartsAt,
    slug = "",
    id = "",
  } = collection;
  const {
    name: platformName,
    logoImageUrl,
    platformColor,
  } = platformData || {};
  let addToGcalLink: string;
  if (mintStartsAt) {
    addToGcalLink = formatGoogleCalendarLinkForCollection({
      collectionName: name,
      artistName,
      startsAt: mintStartsAt,
      slugOrId: slug || id,
    });
  }

  const handleCalendarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault(); // Stop the parent <Link /> which wraps this CollectionCard
    window.open(addToGcalLink, "_blank");
  };

  return (
    <Link
      key={collection._id}
      href={`/collections/${collection.slug || collection.id}`}
    >
      <div
        ref={ref}
        className="space-y-1 overflow-hidden transition asset-card"
      >
        <div className="relative">
          {collection.hidden && (
            <>
              <span className="absolute z-10 flex flex-col items-center justify-center w-full h-full bg-black opacity-30"></span>
              <span className="absolute z-10 flex flex-col items-center justify-center w-full h-full">
                <div className="flex items-center gap-1 px-2 py-1 pr-3 font-medium bg-white rounded-full dark:text-neutral-900 text-p-s">
                  <EyeOff className="flex-shrink-0 w-5 h-5" /> Unpublished
                </div>
              </span>
            </>
          )}

          <CardAssetContained imageUrl={imageUrl || ""} />
          <div className="absolute flex items-center space-x-2 top-2 right-2">
            {mintState === MintState.Upcoming ? (
              <div
                className="px-2 py-1 bg-white rounded-lg hover:bg-neutral-200 dark:bg-neutral-600 dark:hover:bg-neutral-700"
                onClick={handleCalendarClick}
              >
                <CalendarPlus className="dark:text-white" size={20} />
              </div>
            ) : null}
            <MintBadge mintState={mintState} />
          </div>
        </div>
        <div className="flex flex-col items-start justify-between w-full">
          <div className="flex items-center justify-between w-full gap-1">
            <p className="mt-2 line-clamp-1 dark:text-white text-p-lg">
              {name}
            </p>
            {platformData && showPlatformBadge && (
              <div>
                <PlatformBadge
                  key={platformName}
                  name={platformName || ""}
                  size="sm"
                  logoUrl={logoImageUrl || ""}
                  bgColor={platformColor || "#000000"}
                  tooltipContent={platformName}
                  tooltipDirection="left"
                />
              </div>
            )}
          </div>
          {showArtistName && (
            <p className="text-sm line-clamp-1 dark:text-white">{artistName}</p>
          )}
        </div>
        <CollectionCardSubHeading
          collection={collection}
          showStats={showStats}
        />
      </div>
    </Link>
  );
};

export default CollectionCard;
