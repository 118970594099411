import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BuyModal, BuyStep } from "@reservoir0x/reservoir-kit-ui";
import Confetti from "react-confetti";
import { useAuth } from "@/client/components/common/AuthProvider";

import { buildTokenId } from "shared/reservoir";
import { Check } from "client/components/icons/check";
import { Close } from "client/components/icons/close";
import { Loader } from "client/components/icons/loader";
import { Loading } from "client/components/common/Loading";
import analytics from "client/lib/analytics";
import { ConnectWallet } from "client/components/common/modal/ConnectWallet";
import { listAssetListings, readAsset, readCollection } from "client/lib/api";
import { Asset } from "shared/types/asset";
import { Collection } from "shared/types/collection";
import ErrorComponent from "client/components/common/modal/ErrorComponent";
import XShareButton from "../XShareButton";
import { getAssetUrl, getEtherscanTxUrl } from "client/lib/links";
import Alert from "../../frame-design-system/alerts/Alert";
import Button, {
  ButtonLoading,
} from "../../frame-design-system/buttons/Button";
import { FormatCryptoCurrency } from "../FormatCryptoCurrency";
import { FormatCurrency } from "../FormatCurrency";
import { formatUnits } from "viem";
import {
  DEFAULT_CRYPTO_DECIMAL_PRECISION,
  DEFAULT_CURRENCY_DECIMAL_PRECISION,
} from "@/client/lib/numbers";
import { useFundWallet } from "@privy-io/react-auth";

type PurchaseModal = {
  tokenId?: string;
  contractAddress?: string;
  open?: boolean;
  setOpen: (open: boolean) => void;
};

type Stage =
  | "addFunds"
  | "unavailable"
  | "confirm"
  | "approve"
  | "processing"
  | "success";

const PurchaseModal = ({
  tokenId,
  contractAddress,
  open = false,
  setOpen,
}: PurchaseModal) => {
  // tokenId and contractAddress are required
  if (!tokenId || !contractAddress) return null;

  const [confetti, setConfetti] = useState<boolean>(false);

  const handeOnClose = () => {
    setOpen(false);
    setConfetti(false);
  };

  const handleOnSuccess = (txHash?: string) => {
    setConfetti(true);
    trackSuccess(txHash);
  };

  const trackSuccess = (txHash?: string) => {
    analytics.track("Purchase Completed");
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={handeOnClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-40 bg-black bg-opacity-25 dark:bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              {confetti ? <Confetti /> : null}

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-3xl dark:bg-neutral-900 dark:text-white bg-neutral-100">
                  <PurchaseComponent
                    tokenId={tokenId}
                    contractAddress={contractAddress}
                    onClose={handeOnClose}
                    onSuccess={handleOnSuccess}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export const PurchaseComponent = ({
  tokenId,
  contractAddress,
  onClose,
  onSuccess,
}: {
  tokenId: string;
  contractAddress: string;
  onClose: any;
  onSuccess: (tx?: string) => void;
}) => {
  const { address } = useAuth();
  const [asset, setAsset] = useState<Asset | null>();
  const [collection, setCollection] = useState<Collection | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stage, setStage] = useState<Stage>("confirm");

  useEffect(() => {
    if (!contractAddress || !tokenId) return;
    // fetch asset data for the UI (instead of reservoir asset data)
    refreshAsset();

    // fetch asset listings
    // this isn't displayed in UI but is used to refresh listing data for asset
    // currently listing data from reservoir SDK
    listAssetListings(contractAddress, tokenId);
  }, [tokenId, contractAddress]);

  useEffect(() => {
    if (!asset) return;
    fetchCollection();
  }, [asset]);

  const refreshAsset = async () => {
    if (!contractAddress || !tokenId) return;
    try {
      const result = await readAsset(contractAddress, tokenId);
      setAsset(result);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchCollection = async () => {
    try {
      const result = await readCollection(asset?.collectionId as string);
      setCollection(result);
    } catch (err) {
      console.error(err);
    }
  };

  const closeModal = () => {
    if (onClose) onClose();
  };

  return (
    <>
      <div className="p-6 py-4">
        <div className="flex items-center justify-between flex-shrink-0">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
          >
            {getStageTitle(stage)}
          </Dialog.Title>
          <button
            onClick={closeModal}
            className="flex flex-col items-center justify-center w-8 h-8 rounded-full bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700"
          >
            <Close />
          </button>
        </div>
      </div>
      <BuyModal.Custom
        open={true}
        token={buildTokenId(contractAddress, tokenId)}
        onConnectWallet={() => {}}
      >
        {({
          loading: reservoirLoading,
          isFetchingPath,
          tokenData,
          collection: reservoirCollection,
          quantityAvailable,
          quantity,
          averageUnitPrice,
          totalIncludingFees,
          buyResponseFees,
          feeOnTop,
          paymentCurrency,
          paymentTokens,
          buyStep,
          transactionError,
          hasEnoughCurrency,
          addFundsLink,
          steps,
          stepData,
          feeUsd,
          totalUsd,
          usdPrice,
          balance,
          address,
          blockExplorerBaseName,
          isConnected,
          isOwner,
          setPaymentCurrency,
          setQuantity,
          setBuyStep,
          buyToken,
        }) => {
          // See example here: https://github.com/reservoirprotocol/reservoir-kit/blob/main/packages/ui/src/modal/buy/BuyModal.tsx

          useEffect(() => {
            switch (buyStep) {
              case BuyStep.Unavailable:
                setStage("unavailable");
                break;
              case BuyStep.Checkout:
                if (!hasEnoughCurrency) {
                  setStage("addFunds");
                } else {
                  setStage("confirm");
                }
                break;
              case BuyStep.Approving:
                if (stepData?.currentStepItem.txHashes?.[0].txHash) {
                  setStage("processing");
                } else {
                  setStage("approve");
                }
                break;
              case BuyStep.Complete:
                setStage("success");
            }
          }, [buyStep, stepData, steps, hasEnoughCurrency]);

          const executableSteps =
            steps?.filter((step) => step.items && step.items.length > 0) || [];
          const lastStepItems =
            executableSteps[executableSteps.length - 1]?.items || [];
          const finalTxHash =
            lastStepItems[lastStepItems.length - 1]?.txHashes?.[0]?.txHash;

          useEffect(() => {
            if (buyStep === BuyStep.Complete && onSuccess) {
              onSuccess(finalTxHash);
            }
          }, [buyStep]);

          const handleBuyClicked = () => {
            analytics.track("Purchase Started");
            buyToken();
          };

          // Calculation pull from reservoir ParmentDetail component
          // https://github.com/reservoirprotocol/reservoir-kit/blob/main/packages/ui/src/common/PaymentDetails.tsx#L39-L42
          const totalUsdFormatted = formatUnits(
            ((paymentCurrency?.currencyTotalRaw || BigInt(0)) + feeOnTop) *
              (paymentCurrency?.usdPriceRaw || BigInt(0)),
            (paymentCurrency?.decimals || DEFAULT_CRYPTO_DECIMAL_PRECISION) +
              DEFAULT_CURRENCY_DECIMAL_PRECISION
          );

          return (
            <PurchaseBody
              name={asset?.name}
              imageUrl={asset?.imageThumbnailUrl}
              currentWalletAddress={address}
              artistName={collection?.artistName}
              total={paymentCurrency?.currencyTotalRaw}
              totalUsd={totalUsdFormatted}
              currencySymbol={paymentCurrency?.symbol}
              stage={stage}
              error={transactionError || null}
              isFlagged={tokenData?.token?.isFlagged}
              onBuyClicked={handleBuyClicked}
              txHash={finalTxHash}
              royaltyBps={reservoirCollection?.royalties?.bps}
              isLoadingAsset={isLoading}
              isLoadingPrice={reservoirLoading}
              contractAddress={collection?.contractAddress}
              tokenId={asset?.tokenId}
            />
          );
        }}
      </BuyModal.Custom>
    </>
  );
};

export const PurchaseBody = ({
  name,
  imageUrl,
  artistName,
  total,
  totalUsd,
  currencySymbol,
  stage,
  error,
  onBuyClicked,
  txHash,
  currentWalletAddress,
  isFlagged,
  royaltyBps,
  isLoadingAsset = true,
  isLoadingPrice = true,
  contractAddress,
  tokenId,
}: {
  name?: string;
  imageUrl?: string;
  artistName?: string;
  total?: bigint;
  totalUsd?: string; // formatted USD value
  currencySymbol?: string;
  stage?: Stage;
  error?: Error | null;
  onBuyClicked?: () => void;
  isFlagged?: boolean | undefined;
  txHash?: string;
  currentWalletAddress?: string;
  royaltyBps?: number;
  isLoadingAsset?: boolean;
  isLoadingPrice?: boolean;
  contractAddress?: string;
  tokenId?: string;
}) => {
  const renderBody = () => {
    if (!currentWalletAddress) return <ConnectWallet />;

    switch (stage) {
      case "unavailable":
        return <ItemUnavailable />;
      case "addFunds":
        return <AddFunds currentWalletAddress={currentWalletAddress} />;
      case "confirm":
        return (
          <ConfirmBody
            loading={isLoadingPrice || isLoadingAsset}
            isFlagged={isFlagged}
            confirmPurchase={onBuyClicked}
          />
        );
      case "approve":
        return <ApproveBody />;
      case "processing":
        return <ProcessingBody txHash={txHash} />;
      case "success":
        return (
          <SuccessBody
            name={name}
            txHash={txHash}
            contractAddress={contractAddress}
            tokenId={tokenId}
          />
        );
    }
  };

  // if still loading asset show full component loader
  if (isLoadingAsset) return <LoadingState />;

  return (
    <>
      <div className="flex flex-col items-center justify-center h-64 mt-8">
        <img src={imageUrl} className="block h-full shadow-lg bg-neutral-500" />
      </div>
      <div className="px-8 py-6">
        {isLoadingPrice ? (
          <Loading />
        ) : (
          <ItemList
            name={name}
            artistName={artistName}
            total={total}
            currencySymbol={currencySymbol}
            totalUsd={totalUsd}
            royaltyBps={royaltyBps}
          />
        )}

        {error ? <ErrorComponent error={error?.message} /> : null}
        {isFlagged ? (
          <div className="mt-6 -mb-4">
            <Alert
              type="error"
              title="Unable to purchase this item"
              subtitle="This item has been flagged as suspicious and could be stolen. To keep you safe, you won't be able to purchase this item"
            />
          </div>
        ) : null}
      </div>
      {renderBody()}
    </>
  );
};

const LoadingState = () => {
  return (
    <div className="flex items-center justify-center my-10 h-96">
      <Loading />
    </div>
  );
};

const ItemUnavailable = () => {
  return (
    <>
      <div className="px-8 py-6 mx-8 mb-8 rounded-md dark:bg-neutral-800 bg-neutral-200">
        <div className="flex flex-col items-center justify-center">
          <p className="text-center">Item Unavailable</p>
          <div className="mt-1 text-xs text-center">
            <span className="text-xs">
              Sorry this item is no long available for purchase
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const AddFunds = ({
  currentWalletAddress,
}: {
  currentWalletAddress: string;
}) => {
  const { fundWallet } = useFundWallet();
  const addFunds = async () => await fundWallet(currentWalletAddress);
  return (
    <>
      <div className="px-8 py-6 mx-8 mb-8 rounded-md dark:bg-neutral-800 bg-neutral-200">
        <div className="flex flex-col items-center justify-center">
          <p className="text-center">Not enough funds</p>
          <div className="mt-1 text-xs text-center">
            <span className="text-xs">
              Please transfer funds into your wallet to make this purchase.{" "}
            </span>
          </div>
          <button
            onClick={addFunds}
            className="mt-4 text-blue-500 hover:text-blue-600 cursor-pointer"
          >
            Add funds now
          </button>
        </div>
      </div>
    </>
  );
};

const ProcessingBody = ({ txHash }: { txHash?: string }) => {
  return (
    <>
      <div className="px-8 pt-2 pb-8">
        <Loader className="mx-auto text-green-600 duration-300 animate-spin dark:text-green-400" />{" "}
        <p className="mt-2 text-center">Processing Transaction...</p>
        <div className="mt-1 mb-4 text-xs text-center">
          <span className="font-medium">Transaction:</span>{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={txHash ? getEtherscanTxUrl(txHash) : undefined}
            className="transition text-neutral-500 hover:text-neutral-300"
          >
            {txHash?.slice(0, 10)}
          </a>
        </div>
      </div>
    </>
  );
};

const SuccessBody = ({
  name,
  txHash,
  contractAddress,
  tokenId,
}: {
  name?: string;
  txHash?: string;
  contractAddress?: string;
  tokenId?: string;
}) => {
  let tokenLink = getAssetUrl(contractAddress as any, tokenId as any);
  return (
    <>
      <div className="px-8 pt-2 pb-8">
        <div className="p-2 py-4 border dark:border-neutral-800 rounded-xl">
          <Check
            size={32}
            className="mx-auto text-white bg-green-700 rounded-full"
          />
          <p className="mt-3 text-center">Purchase Complete</p>
          <p className="mt-1 mb-3 text-sm text-center">
            {name} is now in your collection
          </p>
          <p className="mt-1 text-xs text-center">
            <span className="font-medium">Transaction:</span>{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://etherscan.io/tx/${txHash}`}
              className="transition text-neutral-500 hover:text-neutral-300"
            >
              {txHash?.slice(0, 10)}
            </a>
          </p>
        </div>

        <div className="mt-4">
          <XShareButton
            socialMsg={`Just picked up ${name} on @artblocks_io! 🎉 \n ${tokenLink}`}
            title={"Share your new piece"}
            location={"purchase modal"}
          />
        </div>
      </div>
    </>
  );
};

const ConfirmBody = ({
  confirmPurchase,
  loading,
  isFlagged,
}: {
  loading: boolean;
  confirmPurchase: any;
  isFlagged: boolean | undefined;
}) => {
  return (
    <>
      <div className="px-8 pt-4 pb-8">
        <Button
          size="large"
          disabled={isFlagged}
          variant={isFlagged ? "inactive" : "primary"}
          onClick={() => {
            if (!isFlagged) {
              confirmPurchase();
            }
          }}
          className="w-full"
        >
          {loading ? <ButtonLoading /> : "Complete Purchase"}
        </Button>
      </div>
    </>
  );
};

const ApproveBody = () => {
  return (
    <>
      <div className="px-4 py-6 border-t dark:border-neutral-700">
        <div className="flex items-center gap-4">
          <Loader className="text-green-600 duration-300 animate-spin" />
          <div className="flex flex-col gap-1">
            <p className="text-sm font-medium">Approve Purchase</p>
            <p className="text-sm">
              You’ll be prompted to approve this purchase from your wallet
            </p>
            <p className="text-sm text-neutral-500">Awaiting Approval...</p>
          </div>
        </div>
      </div>
    </>
  );
};

const ItemList = ({
  name,
  artistName,
  total,
  currencySymbol,
  totalUsd,
  royaltyBps,
}: {
  name?: string;
  artistName?: string;
  total?: bigint;
  currencySymbol?: string;
  totalUsd?: string;
  royaltyBps?: number;
}) => {
  return (
    <>
      <div className="flex justify-between font-medium">
        <p>Item</p>
        <p className="text-right">Total</p>
      </div>
      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center gap-4">
          <div className="text-sm">
            <p className="text-base">{name}</p>
            <p>{artistName}</p>
            <p className="text-xs text-neutral-500" style={{ marginTop: 2 }}>
              Royalties:{" "}
              {royaltyBps ? `${(royaltyBps / 100)?.toFixed(1)}%` : "-"}
            </p>
          </div>
        </div>

        <div className="text-right flex-end">
          <span className="flex items-center justify-end font-medium">
            <FormatCryptoCurrency amount={total} symbol={currencySymbol} />
          </span>
          <span className="justify-end text-sm">
            <FormatCurrency amount={totalUsd} />
          </span>
        </div>
      </div>
    </>
  );
};

const getStageTitle = (stage?: Stage) => {
  switch (stage) {
    case "approve":
      return "Awaiting Approval";
    case "processing":
      return "";
    default:
      return "Checkout";
  }
};

export default PurchaseModal;
