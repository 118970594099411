import type { NextPage } from "next";
import Footer from "@/client/components/common/Footer";
import MainContainer from "@/client/components/common/MainContainer";
import MetadataHead from "@/client/components/common/MetdataHead";
import RecentCollectorSales from "./RecentCollectorSales";
import RecentFloorListingActivity from "./RecentFloorListingActivity";
import RecentSaleActivity from "./RecentSaleActivity";
import { CollectionFeatureCard } from "../../common/CollectionFeatureCard";
import { Collection } from "@/shared/types/collection";
import { SectionTitle } from "@/client/components/frame-design-system/text/SectionTitle";
import { CardCarousel } from "@/client/components/common/CardCarousel";
import { listFeaturedArtBlocksPlatforms } from "@/shared/platforms";
import { StudioBanner } from "./StudioBanner";
import { CollectionCardSection } from "@/client/components/common/CollectionCardSection";

export type Props = {
  featuredCollection?: Collection;
  upcomingCollectionReleases?: Collection[];
  recentCollectionReleases?: Collection[];
};

export const HomePage: NextPage<Props> = ({
  featuredCollection,
  upcomingCollectionReleases,
  recentCollectionReleases,
}: Props) => {
  const artBlocksCategories = listFeaturedArtBlocksPlatforms();

  return (
    <>
      <MetadataHead />
      {featuredCollection && (
        <div
          style={{
            marginTop: "-80px",
          }}
          className="sm:h-[calc(90svh)]"
        >
          <CollectionFeatureCard collection={featuredCollection} />
        </div>
      )}

      <MainContainer>
        {upcomingCollectionReleases?.length ? (
          <section className="mt-12 sm:mt-20 sm:mb-16">
            <CollectionCardSection
              title="Upcoming Releases"
              subtitle="Check out what’s on the horizon"
              collections={upcomingCollectionReleases || []}
              navElementLink={"/calendar"}
            />
          </section>
        ) : null}
        {recentCollectionReleases?.length ? (
          <section className="mt-12 sm:mt-20 sm:mb-24">
            <CollectionCardSection
              title="Recent Releases"
              subtitle="Explore the newest collections"
              collections={recentCollectionReleases || []}
            />
          </section>
        ) : null}

        <section className="my-16 sm:my-28">
          <StudioBanner />
        </section>
        <section className="mt-12 sm:mt-20 sm:mb-44 space-y-8">
          <SectionTitle level="h3" title="Discover Art Blocks" />
          <CardCarousel data={artBlocksCategories} cardType="platform" />
        </section>
        <section className="mt-12 sm:mt-20 sm:mb-44">
          <RecentFloorListingActivity />
        </section>
        <section className="mt-12 sm:mt-20 sm:mb-44">
          <RecentSaleActivity />
        </section>
        <section className="mt-12 sm:mt-20 sm:mb-44">
          <RecentCollectorSales />
        </section>
      </MainContainer>
      <Footer />
    </>
  );
};

export default HomePage;
