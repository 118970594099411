import { MoonIcon, SunIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import Link from "next/link";

import Account from "./Account";
import { Close as CloseIcon } from "@/client/components/icons/close";
import Logo from "@/client/components/common/Logo";
import Dropdown from "@/client/components/frame-design-system/menus/Dropdown";

interface MobileMenu {
  show: boolean;
  closeMenu: any;
  darkMode: boolean;
  setDarkMode: any;
}

export const MobileMenu = ({
  show = false,
  closeMenu,
  darkMode,
  setDarkMode,
}: MobileMenu) => {
  const [exploreOpen, setExploreOpen] = useState<boolean>(false);
  const [statsOpen, setStatsOpen] = useState<boolean>(false);
  const [applyOpen, setApplyOpen] = useState<boolean>(false);
  const [aboutOpen, setAboutOpen] = useState<boolean>(false);

  const listItemClasses =
    "hover:opacity-70 p-m py-2 block text-neutral-600 dark:text-neutral-200 flex items-center gap-2";

  return (
    <div
      className={`bg-white dark:bg-neutral-900 dark:text-white w-full h-full top-0 transition-all duration-500 fixed shadow-xl ${
        show ? "left-0" : "-left-full"
      }`}
    >
      <div className="w-full h-20 px-8 lg:hidden flex items-center justify-between">
        <Logo />
        <button onClick={() => closeMenu()}>
          <CloseIcon className="h-8 w-8" />
        </button>
      </div>
      <div className="mt-4 px-8 flex flex-col gap-2">
        <Dropdown
          open={exploreOpen}
          packed={false}
          setOpen={setExploreOpen}
          title="Explore"
          weight="medium"
        />
        {exploreOpen ? (
          <>
            <Link href="/explore" className={listItemClasses}>
              Collections
            </Link>
            <Link href="/calendar" className={listItemClasses}>
              Calendar
            </Link>
            <Link href="/experiments" className={listItemClasses}>
              Experiments
            </Link>
          </>
        ) : null}
        <Dropdown
          open={statsOpen}
          packed={false}
          setOpen={setStatsOpen}
          title="Stats"
          weight="medium"
        />
        {statsOpen ? (
          <>
            <Link href="/rankings" className={listItemClasses}>
              Rankings
            </Link>
            <Link href="/activity" className={listItemClasses}>
              Activity
            </Link>
          </>
        ) : null}
        <Dropdown
          open={applyOpen}
          packed={false}
          setOpen={setApplyOpen}
          title="Apply"
          weight="medium"
        />
        {applyOpen ? (
          <>
            <Link href="/studio/apply" className={listItemClasses}>
              Apply to Release
            </Link>
            <Link href="/studio/apply/residency" className={listItemClasses}>
              Apply for Artist Residency
            </Link>
          </>
        ) : null}
        <Dropdown
          open={aboutOpen}
          packed={false}
          setOpen={setAboutOpen}
          title="On-Chain"
          weight="medium"
        />
        {aboutOpen ? (
          <>
            <Link href="/onchain" className={listItemClasses}>
              On-Chain
            </Link>
            <a
              href="https://www.artblocks.io/curated/articles/about-art-blocks-curated"
              className={listItemClasses}
            >
              About Art Blocks
            </a>
          </>
        ) : null}
        <Account menuType="mobile" />
      </div>
      <div className="px-8 my-6">
        <div className="w-full h-px  mb-4 bg-neutral-100 dark:bg-neutral-800" />
        <button
          className={listItemClasses}
          onClick={() => setDarkMode((prev: boolean) => !prev)}
        >
          {darkMode ? (
            <>
              <SunIcon className={`text-[rgba(255,255,255,.8)] w-5 h-5`} />
              <p className="font-medium text-[rgba(255,255,255,.8)]">
                Light Mode
              </p>
            </>
          ) : (
            <>
              <MoonIcon className={`w-5 h-5 text-neutral-600`} />
              <p className="font-medium text-neutral-600">Dark Mode</p>
            </>
          )}
        </button>
      </div>
    </div>
  );
};
