import { cn } from "@/client/lib/classnames";
import Link, { LinkProps } from "next/link";
import { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";

type ButtonProps = { as?: "button" } & ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorProps = { as: "a" } & AnchorHTMLAttributes<HTMLAnchorElement>;
type LinkComponentProps = { as: "Link" } & LinkProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href">;

export type SimpleButtonProps = {
  variant?: "primary" | "secondary" | "outline";
  icon?: React.ReactElement;
} & (ButtonProps | AnchorProps | LinkComponentProps);

const SimpleButton = ({
  icon,
  variant,
  className,
  children,
  as = "button",
  ...props
}: SimpleButtonProps) => {
  let globalStyles = `transition font-medium rounded-xl flex items-center disabled:opacity-50`;

  const buttonStyle = () => {
    switch (variant) {
      case "secondary":
        return "bg-neutral-200";
      case "outline":
        return "border border-neutral-200 text-neutral-900 hover:bg-neutral-100 hover:border-neutral-300 dark:hover:bg-neutral-800 dark:border-neutral-700 dark:text-white";
      default:
        // this handles primary so no need to declare twice
        return "bg-neutral-900 text-white hover:bg-neutral-700 dark:bg-white dark:text-neutral-900 dark:hover:bg-neutral-300";
    }
  };

  if (as === "Link") {
    const {
      href,
      as,
      replace,
      scroll,
      shallow,
      passHref,
      prefetch,
      locale,
      legacyBehavior,
      onMouseEnter,
      onTouchStart,
      onClick,
      ...rest
    } = props as LinkComponentProps;

    return (
      <Link
        href={href}
        passHref
        className={cn(`${buttonStyle()} ${globalStyles}`, className)}
        {...rest}
      >
        {icon ? icon : null}
        {children}
      </Link>
    );
  }

  if (as === "a") {
    const { as, ...rest } = props as AnchorProps;
    return (
      <a
        className={cn(`${buttonStyle()} ${globalStyles}`, className)}
        {...rest}
      >
        {icon ? icon : null}
        {children}
      </a>
    );
  }

  return (
    <button
      className={cn(`${buttonStyle()} ${globalStyles}`, className)}
      {...(props as ButtonProps)}
    >
      {icon ? icon : null}
      {children}
    </button>
  );
};

export default SimpleButton;
