import { GraphQLClient } from "graphql-request";

import { Asset } from "@/shared/types/asset";
import { Platform, ArtBlocksPlatformKind } from "./platforms";
import { getBasePath, isTestnet } from "@/shared/config";
import { isAddress } from "@/shared/eth";

export const TOKEN_ID_GENERATION_VALUE = 1000000;
export const MEDIA_BASE_URL = "https://media.artblocks.io";
export const MEDIA_PROXY_BASE_URL = "https://media-proxy.artblocks.io";
export const MEDIA_AB_X_PACE_BASE_URL =
  "https://art-blocks-x-pace-mainnet.s3.amazonaws.com";
export const MEDIA_AB_EXPLORATIONS_BASE_URL =
  "https://art-blocks-explorations-mainnet.s3.amazonaws.com";
export const MEDIA_BRIGHT_MOMENTS_BASE_URL =
  "https://bright-moments-mainnet.s3.amazonaws.com";
export const MEDIA_BRIGHT_MOMENTS_CRYPTO_CITIZENS_BASE_URL =
  "https://crypto-citizens-mainnet.s3.amazonaws.com";
export const MEDIA_TENNIS_ART_BASE_URL =
  "https://tennis-art-mainnet.s3.amazonaws.com";

const HASURA_BASE_URL = process.env
  .NEXT_PUBLIC_HASURA_GRAPHQL_ENDPOINT as string;

export function getArtBlocksGraphQLEndpoint() {
  return HASURA_BASE_URL;
}

/**
 * GraphQL client for interacting with the Art Blocks API.
 */
const abGraphQLClient = new GraphQLClient(HASURA_BASE_URL);

export const parseTokenId = (tokenId: string) => {
  const projectId = `${Math.floor(Number(tokenId) / TOKEN_ID_GENERATION_VALUE)}`;
  const tokenIndex = Number(tokenId) % TOKEN_ID_GENERATION_VALUE;
  return { projectId, tokenIndex };
};

export const generateTokenId = (projectId: number, tokenIndex: number) => {
  return projectId * TOKEN_ID_GENERATION_VALUE + tokenIndex;
};

export const generateDefaultTokenId = (projectId: number) => {
  return generateTokenId(projectId, 0);
};

/**
 * Checks is a string is a valid database ID format
 *
 * format of `contractAddress-projectId`
 */
export const isProjectDatabaseIdFormat = (id: string) => {
  // should have 2 parts split by a `-`
  const parts = id.split("-");
  if (parts.length !== 2) return false;

  // the first part should be a contract address format
  if (!isAddress(parts[0])) return false;

  // the second part should look like a number
  if (isNaN(Number(parts[1]))) return false;

  return true;
};

/**
 * Takes a database ID and returns the contract and project ID
 *
 * Expects a database ID in the format of `contractAddress-projectId`
 */
export const parseProjectDatabaseId = (id: string) => {
  const parts = id.split("-");
  if (parts.length !== 2) throw new Error("Invalid database ID format");

  return { contractAddress: parts[0], projectId: parts[1] };
};

/**
 * Takes a database ID and returns the contract and token ID
 *
 * Expects a database ID in the format of `contractAddress-tokenId`
 */
export const parseTokenDatabaseId = (id: string) => {
  const parts = id.split("-");
  if (parts.length !== 2) throw new Error("Invalid database ID format");

  return { contractAddress: parts[0], tokenId: parts[1] };
};

/**
 * Takes a contract and project ID and returns the database ID
 */
export const generateProjectDatabaseId = (
  contractAddress: string,
  projectId: string
) => {
  return `${contractAddress}-${projectId}`;
};

/**
 * Takes a contract and token ID and returns the database ID
 *
 * Returns a database ID in the format of `contractAddress-tokenId`
 */
export const generateProjectDatabaseIdFromTokenID = (
  contractAddress: string,
  tokenId: string
) => {
  return `${contractAddress}-${(parseInt(tokenId) / TOKEN_ID_GENERATION_VALUE).toFixed(0)}`;
};

export const isArtBlocksAsset = (asset: Asset) => {
  return Boolean(asset.artBlocksProjectId);
};

export const getGraphQlClient = () => {
  return abGraphQLClient;
};

const PLATFORM_TO_GRADIENT_RGB = {
  [Platform.ArtBlocksCurated]: {
    from: "0, 0, 0",
    to: "0, 0, 0",
  },
  [Platform.ArtBlocksPresents]: {
    from: "253, 200, 0",
    to: "254, 110, 0",
  },
  [Platform.ArtBlocksCollaborations]: {
    from: "254, 115, 0",
    to: "255, 0, 0",
  },
  [Platform.ArtBlocksPlayground]: {
    from: "255, 0, 0",
    to: "255, 0, 255",
  },
  [Platform.ArtBlocksFactory]: {
    from: "255, 0, 255",
    to: "0, 0, 255",
  },
  [Platform.ArtBlocksExplorations]: {
    from: "0, 0, 255",
    to: "0, 255, 255",
  },
  [Platform.ArtBlocksStudio]: {
    from: "0,220,255",
    to: "0, 255, 0",
  },
} as const;

const PLATFORM_TO_LOCKUP_IMAGE_URL = {
  [Platform.ArtBlocksCurated]: `${getBasePath()}/platforms/artblocks-curated-lockup.svg`,
  [Platform.ArtBlocksPresents]: `${getBasePath()}/platforms/artblocks-presents-lockup.svg`,
  [Platform.ArtBlocksCollaborations]: `${getBasePath()}/platforms/artblocks-collaborations-lockup.svg`,
  [Platform.ArtBlocksPlayground]: `${getBasePath()}/platforms/artblocks-playground-lockup.svg`,
  [Platform.ArtBlocksFactory]: `${getBasePath()}/platforms/artblocks-factory-lockup.svg`,
  [Platform.ArtBlocksExplorations]: `${getBasePath()}/platforms/artblocks-explorations-lockup.svg`,
  [Platform.ArtBlocksStudio]: `${getBasePath()}/platforms/artblocks-studio-lockup.svg`,
} as const;

export const getPlatformColorGradient = (platform: ArtBlocksPlatformKind) => {
  return PLATFORM_TO_GRADIENT_RGB[platform] || null;
};

export const getArtBlocksPlatformLockup = (platform: ArtBlocksPlatformKind) => {
  return PLATFORM_TO_LOCKUP_IMAGE_URL[platform] || null;
};

export const getGeneratorUrl = ({
  contractAddress,
  tokenId,
}: {
  contractAddress: string;
  tokenId: string;
}): string => {
  const baseUrl = isTestnet() ? "https://generator.development.artblocks.io" : "https://generator.artblocks.io";
  return `${baseUrl}/${contractAddress}/${tokenId}?source=tokenRenderer`;
};
