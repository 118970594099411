"use client";

import * as React from "react";
import { createContext, useContext, useEffect, useCallback } from "react";
import { useDisconnect, useWalletClient } from "wagmi";
import { useLogin, usePrivy } from "@privy-io/react-auth";
import { logout, privyAuthenticate } from "@/client/lib/api";

type AuthContextType = {
  address: string | undefined;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isReady: boolean;
  login: () => void;
  logout: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const {
    ready: privyReady,
    authenticated: privyAuthenticated,
    isModalOpen: isPrivyModalOpen,
    user: privyUser,
    logout: privyLogout,
  } = usePrivy();

  const { login } = useLogin({
    onComplete: async (user, wasAlreadyAuthenticated) => {
      const publicAddress = user?.wallet?.address;
      if (!wasAlreadyAuthenticated && publicAddress) {
        try {
          await privyAuthenticate(publicAddress);
        } catch (error) {
          console.log(`Error setting Hasura token: ${error}`);
        }
      }
    },
    onError: (error) => {
      console.log(`Privy Login Error: ${error}`);
    },
  });

  const handleLogin = async () => {
    // if the user is in a wierd state then force them to logout before trying to login
    // TODO: figure out how to prevent user from getting in this state
    // FIXME:
    const wagmiAuthenticated = !!wagmiWalletClient?.account.address;
    if (privyAuthenticated && !wagmiAuthenticated) await handleLogout();

    login();
  };

  const { isPending: wagmiIsPending, data: wagmiWalletClient } =
    useWalletClient();
  const { disconnect: wagmiDisconnect } = useDisconnect();

  const handleLogout = useCallback(async () => {
    console.log("handleLogout");
    // logout of privy
    await privyLogout();

    // disconnect from wagmi (privy does not auto disconnect from wagmi)
    wagmiDisconnect?.();

    // clear the Hasura token cookie
    await logout();
  }, [wagmiDisconnect, privyLogout]);

  useEffect(() => {
    if (
      privyReady &&
      privyAuthenticated &&
      !wagmiIsPending &&
      (!wagmiWalletClient ||
        wagmiWalletClient.account.address !== privyUser?.wallet?.address)
    ) {
      handleLogout();
    }
  }, [
    privyReady,
    privyUser?.wallet?.address,
    privyAuthenticated,
    wagmiIsPending,
    wagmiWalletClient,
    handleLogout,
  ]);

  const value = {
    address:
      privyReady && privyAuthenticated && privyUser
        ? wagmiWalletClient?.account.address
        : undefined, // only return the address if we are authenticated (signed a message)
    isAuthenticated: privyAuthenticated && !!wagmiWalletClient?.account.address, // privy & wagmi are authenticated
    isAuthenticating: isPrivyModalOpen,
    isReady: privyReady,
    login: handleLogin,
    logout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
