import { getBasePath, isTestnet } from "@/shared/config";

const APP_BASE_URL = "https://artblocks.io";
export const LEGACY_APPLICATION_URL = "https://www.artblocks.io/legacy/apply";

const AB_FLAGSHIP_URL = isTestnet()
  ? "https://dev.artblocks.io"
  : "https://artblocks.io";

export const getBaseUrl = () => {
  if (getBasePath()) return `${APP_BASE_URL}${getBasePath()}`;
  return APP_BASE_URL;
};

export const getEtherscanTxUrl = (txHash: string) => {
  return isTestnet()
    ? `https://sepolia.etherscan.io/tx/${txHash}`
    : `https://etherscan.io/tx/${txHash}`;
};

export const getEtherscanWalletUrl = (walletAddress: string) => {
  return isTestnet()
    ? `https://sepolia.etherscan.io/address/${walletAddress}`
    : `https://etherscan.io/address/${walletAddress}`;
};

export const getArtistCanonicalPath = ({
  id,
  slug,
}: {
  id: string;
  slug?: string;
}) => {
  return `/artists/${slug || id}`;
};

export const getArtistCanonicalUrl = ({
  id,
  slug,
}: {
  id: string;
  slug?: string;
}) => {
  return `${getBaseUrl()}${getArtistCanonicalPath({ id, slug })}`;
};

export const getCollectionCanonicalUrl = ({
  id,
  slug,
}: {
  id: string;
  slug?: string;
}) => {
  return `${getBaseUrl()}/collections/${slug || id}`;
};

export const getCollectionPath = (idOrSlug: string) => {
  return `/collections/${idOrSlug}`;
};

export const getCollectionUrl = (idOrSlug: string) => {
  return `${getBaseUrl()}${getCollectionPath(idOrSlug)}`;
};

export const getAssetUrl = (contractAddress: string, tokenId: string) => {
  return `${getBaseUrl()}/asset/${contractAddress}/${tokenId}`;
};

export const getArtBlocksProjectUrl = (projectId: string) => {
  return `${AB_FLAGSHIP_URL}/project/${projectId}`;
};

export const getAssetPath = (contractAddress: string, tokenId: string) => {
  return `/asset/${contractAddress}/${tokenId}`;
};

export const getPrivacyPolicyUrl = () => {
  return `https://www.artblocks.io/privacy-policy.pdf`;
};

export const getTermsOfServiceUrl = () => {
  return `https://www.artblocks.io/user-terms.pdf`;
};

export const getWalletPath = (walletAddress: string) => {
  return `/wallets/${walletAddress}`;
};

export const getWalletUrl = (walletAddress: string) => {
  return `${getBaseUrl()}${getWalletPath(walletAddress)}`;
};
